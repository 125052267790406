import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useWindowDimensions from "../utils/windowSize";
import { ACTIVE_PAGE } from "../components/nav/utils";
import DropdownMenu from "../components/DropdownMenu";
import { useEffect, useState } from "react";
import {
  DeliverableIframe,
  HeaderTextContainer,
  IframeWrapper,
  NoScrollContainer,
} from "../components/styled-components/Globals/GlobalStyledComponents";
import {
  PageNavDirection,
  PageNavNext,
  PageNavNextName,
  PageNavigation,
} from "../components/styled-components/Globals/PageNavigation";

const getIndex = (searchQuery: string) => {
  const urlQuery = /^(?:\?view=)(.*)/.exec(searchQuery);
  const query = urlQuery ? urlQuery[1].replaceAll("+", " ") : null;
  if (!query) return 2;

  const titles = threeDLinks.map((link) => link.title);
  if (titles.includes(query))
    return titles.findIndex((title) => title === query);
  else return 0;
};

export default function ThreeDPage() {
  const { width } = useWindowDimensions();
  const [linkIndex, setLinkIndex] = useState<number | null>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLinkIndex(getIndex(window.location.search));
    }
  }, []);

  const handleClick = (title: string, index: number) => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href);
      url.searchParams.set("view", title);
      window.history.pushState({}, "", url.toString());
      setLinkIndex(index);
    }
  };

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.THREED}>
      <Seo
        title="BIM Modeling Services | Robotic Imaging"
        description="Sample Models from BIM Services provided by Robotic Imaging."
      />

      <NoScrollContainer>
        <header className="site__header">
          <HeaderTextContainer>
            <strong>Transformative Scan to Revit Solutions</strong>
            <p>
              <span>
                Revolutionize how you design, build and maintain with our
                cutting-edge Scan to Revit service. AEC professionals can now
                seamlessly convert laser scan data into accurate, intelligent 3D
                Revit models, saving time and reducing errors.
              </span>
              <span>
                Whether you're renovating an existing structure or planning new
                construction, our solution captures complex geometries and
                creates the building elements with extreme detail. With fast
                turnaround and precision laser scan backgrounds, you'll have
                more time to focus on creativity and design.
              </span>
            </p>
          </HeaderTextContainer>

          <div className="site__header__button-container-tags">
            {threeDLinks.map((link, index) => (
              <button
                key={link.title}
                onClick={() => handleClick(link.title, index)}
                disabled={linkIndex === index}
                className={`site__header__button${
                  linkIndex === index ? "-disabled" : ""
                }`}
              >
                {link.title}
              </button>
            ))}
          </div>

          <DropdownMenu
            index={linkIndex}
            setIndex={setLinkIndex}
            options={threeDLinks}
          />
        </header>

        <IframeWrapper>
          <DeliverableIframe
            title="three-d-drawing"
            src={linkIndex !== null ? threeDLinks[linkIndex].url : ""}
          />
        </IframeWrapper>
      </NoScrollContainer>

      <PageNavigation>
        <PageNavNext to="/2D" title="Next page">
          <div>
            <PageNavDirection>Next Page</PageNavDirection>
            <ChevronRightIcon />
          </div>
          {width && width >= 650 ? (
            <PageNavNextName>2D Imaging</PageNavNextName>
          ) : null}
        </PageNavNext>
      </PageNavigation>
    </SiteWrapper>
  );
}

export const threeDLinks = [
  {
    title: "LOD200 - Warehouse",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c99fa178086df447d02?mode=embed",
  },
  {
    title: "LOD300 - Restaurant",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c99599f3dc72151fdbd?mode=embed",
  },
  {
    title: "LOD300 - Education",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c9911b82f6ea18d5dc0?mode=embed",
  },
  {
    title: "LOD400 - Commercial Adaptive Re Use",
    url: "https://myhub.autodesk360.com/ue2a16417/g/shares/SHd38bfQT1fb47330c9953bdda080590e1bf?mode=embed",
  },
  {
    title: "LOD400 - Retail",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c996fefe687637e4daf?mode=embed",
  },
  {
    title: "LOD400 - Residential",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c99b0155a43b174e5cd?mode=embed",
  },
  {
    title: "LOD400 - Adaptive Storage",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c9945e8cd37338c2250?mode=embed",
  },
  {
    title: "LOD200 - Kitchen",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c99ea0da9fc190fac00?mode=embed",
  },
];
